import { FC, PropsWithChildren, ReactNode } from "react";

interface IconWithDataProps {
  icon: ReactNode;
  defineTitle: string;
}

const IconWithData: FC<PropsWithChildren<IconWithDataProps>> = ({
  children,
  icon,
  defineTitle,
}) => {
  return (
    <div className="flex items-start gap-4">
      <span className="fill-gray-300">{icon}</span>
      <dl>
        <dt className="sr-only">{defineTitle}</dt>
        <dd className="text-xs text-tertiary">{children}</dd>
      </dl>
    </div>
  );
};
export default IconWithData;
