import { FC } from "react";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { RedirectReason } from "@/domain/values/RedirectReason";
import { getReasonText } from "@/utils/getReasonText";
import IconLike from "@/assets/imgs/svg/icon-like.svg";
import IconLikeFill from "@/assets/imgs/svg/icon-like-fill.svg";

type Props = {
  hasLiked: boolean;
  onClick: VoidFunction;
};

const MinimumLikeBtn: FC<Props> = ({ hasLiked, onClick }) => {
  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    action: onClick,
    message: getReasonText(RedirectReason.Like),
  });

  return (
    <>
      <button
        onClick={executeAction}
        title={`LIKEを${hasLiked ? "はずす" : "つける"}`}
      >
        <span
          className={[
            "block border-body-primary rounded-xs p-4 text-primary shadow-default hover:shadow-none transition duration-200 bg-white",
            hasLiked && "fill-like",
          ].join(" ")}
        >
          {hasLiked ? (
            <IconLikeFill width={20} height={20} />
          ) : (
            <IconLike width={20} height={20} />
          )}
        </span>
      </button>
      {SignInModalComponent}
    </>
  );
};

export default MinimumLikeBtn;
